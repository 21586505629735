import React, { useEffect, useState } from "react";
// import { Route, IndexRoute } from "react-router";
import { Helmet } from "react-helmet";
import Current from "./components/current";
import Picker from "./components/picker";
import About from "./components/about";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { romanizeNumber } from "./romanjava";
const App = ({ children }) => {
  const [date, setDate] = useState(new Date());
  const [dateChanged, setDateChanged] = useState(false);
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dies Romani • Roman Date Picker</title>
        <meta
          name="description"
          content="Pick a date/time and see what its correct name is in Latin according to the Roman Calendar and time system."
        />
        <link rel="canonical" href="https://diesromani.com" />
      </Helmet>
      <Router>
        <div>
          <nav
            style={{
              position: "fixed",

              width: "100%",
            }}
          >
            <ul>
              <li style={{ display: "inline-block", paddingRight: "2rem" }}>
                <Link to="/">Dies Romani</Link>
              </li>

              <li style={{ display: "inline-block" }}>
                <Link to="/about">About</Link>
              </li>
            </ul>
          </nav>

          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/about">
              <About date={date} />
            </Route>
            <Route path="/">
              <Picker
                dateChanged={dateChanged}
                setDateChanged={setDateChanged}
                date={date}
                setDate={setDate}
              />
            </Route>
          </Switch>
          <footer>
            ©{" "}
            <a
              className="unstyled-link"
              href="https://rooftopproductions.hk/ivor-houlker"
            >
              Ivor Houlker
            </a>{" "}
            {romanizeNumber(new Date().getFullYear())}
          </footer>
        </div>
      </Router>
    </div>
  );
};

export default App;
