/*
	Date & Time Functions
*/

//return true if it's a leapyear
/**
 *	Sunrise/sunset script. By Matt Kane.
 *
 *  Based loosely and indirectly on Kevin Boone's SunTimes Java implementation
 *  of the US Naval Observatory's algorithm.
 *
 *  Copyright © 2012 Triggertrap Ltd. All rights reserved.
 *
 * This library is free software; you can redistribute it and/or modify it under the terms of the GNU Lesser General
 * Public License as published by the Free Software Foundation; either version 2.1 of the License, or (at your option)
 * any later version.
 *
 * This library is distributed in the hope that it will be useful,but WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU Lesser General Public License for more
 * details.
 * You should have received a copy of the GNU Lesser General Public License along with this library; if not, write to
 * the Free Software Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301  USA,
 * or connect to: http://www.gnu.org/licenses/old-licenses/lgpl-2.1.html
 */
const DEGREES_PER_HOUR = 360 / 24;
const sunrise = (date, latitude, longitude, zenith) => {
  return sunriseSet(date, latitude, longitude, true, zenith);
};

const sunset = (date, latitude, longitude, zenith) => {
  return sunriseSet(date, latitude, longitude, false, zenith);
};

const sunriseSet = (date, latitude, longitude, sunrise, zenith) => {
  if (!zenith) {
    zenith = 90.8333;
  }

  var hoursFromMeridian = longitude / DEGREES_PER_HOUR,
    dayOfYear = getDayOfYear(date),
    approxTimeOfEventInDays,
    sunMeanAnomaly,
    sunTrueLongitude,
    ascension,
    rightAscension,
    lQuadrant,
    raQuadrant,
    sinDec,
    cosDec,
    cosLocalHourAngle,
    localHourAngle,
    localHour,
    localMeanTime,
    time;

  if (sunrise) {
    approxTimeOfEventInDays = dayOfYear + (6 - hoursFromMeridian) / 24;
  } else {
    approxTimeOfEventInDays = dayOfYear + (18.0 - hoursFromMeridian) / 24;
  }

  sunMeanAnomaly = 0.9856 * approxTimeOfEventInDays - 3.289;

  sunTrueLongitude =
    sunMeanAnomaly +
    1.916 * Math.sinDeg(sunMeanAnomaly) +
    0.02 * Math.sinDeg(2 * sunMeanAnomaly) +
    282.634;
  sunTrueLongitude = Math.mod(sunTrueLongitude, 360);

  ascension = 0.91764 * Math.tanDeg(sunTrueLongitude);
  rightAscension = (360 / (2 * Math.PI)) * Math.atan(ascension);
  rightAscension = Math.mod(rightAscension, 360);

  lQuadrant = Math.floor(sunTrueLongitude / 90) * 90;
  raQuadrant = Math.floor(rightAscension / 90) * 90;
  rightAscension = rightAscension + (lQuadrant - raQuadrant);
  rightAscension /= DEGREES_PER_HOUR;

  sinDec = 0.39782 * Math.sinDeg(sunTrueLongitude);
  cosDec = Math.cosDeg(Math.asinDeg(sinDec));
  cosLocalHourAngle =
    (Math.cosDeg(zenith) - sinDec * Math.sinDeg(latitude)) /
    (cosDec * Math.cosDeg(latitude));

  localHourAngle = Math.acosDeg(cosLocalHourAngle);

  if (sunrise) {
    localHourAngle = 360 - localHourAngle;
  }

  localHour = localHourAngle / DEGREES_PER_HOUR;

  localMeanTime =
    localHour + rightAscension - 0.06571 * approxTimeOfEventInDays - 6.622;

  time = localMeanTime - longitude / DEGREES_PER_HOUR;
  time = Math.mod(time, 24);

  var midnight = new Date(0);
  midnight.setUTCFullYear(date.getUTCFullYear());
  midnight.setUTCMonth(date.getUTCMonth());
  midnight.setUTCDate(date.getUTCDate());

  var milli = midnight.getTime() + time * 60 * 60 * 1000;

  return new Date(milli);
};

// Utility functions

const getDayOfYear = (date) => {
  var onejan = new Date(date.getFullYear(), 0, 1);
  return Math.ceil((date - onejan) / 86400000);
};

Math.degToRad = function (num) {
  return (num * Math.PI) / 180;
};

Math.radToDeg = function (radians) {
  return (radians * 180.0) / Math.PI;
};

Math.sinDeg = function (deg) {
  return Math.sin((deg * 2.0 * Math.PI) / 360.0);
};

Math.acosDeg = function (x) {
  return (Math.acos(x) * 360.0) / (2 * Math.PI);
};

Math.asinDeg = function (x) {
  return (Math.asin(x) * 360.0) / (2 * Math.PI);
};

Math.tanDeg = function (deg) {
  return Math.tan((deg * 2.0 * Math.PI) / 360.0);
};

Math.cosDeg = function (deg) {
  return Math.cos((deg * 2.0 * Math.PI) / 360.0);
};

Math.mod = function (a, b) {
  var result = a % b;
  if (result < 0) {
    result += b;
  }
  return result;
};

const isLeapYear = (year) => {
  return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
};

export const romanHour = (iDate) => {
  const midnight = new Date(iDate).setHours(0, 0, 0, 0);
  const msPastMidnight = iDate - midnight;
  //   const position = navigator.geolocation.getCurrentPosition();
  const position = {
    coords: {
      latitude: 41.9028,
      longitude: 12.4964,
    },
  };
  const lat = position.coords.latitude;
  const lon = position.coords.longitude;

  const sunrise = sunriseSet(iDate, lat, lon, true) - midnight;
  const sunset = sunriseSet(iDate, lat, lon, false) - midnight;
  const msInDay = sunset - sunrise;
  const twentyFourHours = 24 * 60 * 60 * 1000;
  const msInNight = twentyFourHours - msInDay;
  const dayHourLength = msInDay / 12;
  const nightHourLength = msInNight / 12;
  const vigils = [
    "vigilia prima",
    "vigilia secunda",
    "vigilia tertia",
    "vigilia quarta",
  ];
  if (msPastMidnight < sunrise) {
    //still night before dawn
    const hoursPastSunset = msToTime(
      msPastMidnight + (twentyFourHours - sunset),
      nightHourLength
    );
    return vigils[Math.floor(hoursPastSunset / 3)];
  } else if (msPastMidnight < sunset) {
    //daylight hours
    const dayHours = [
      "prima diei hora",
      "secunda diei hora",
      "tertia diei hora",
      "quarta diei hora",
      "quinta diei hora",
      "sexta diei hora",
      "septima diei hora",
      "octava diei hora",
      "nona diei hora",
      "decima diei hora",
      "undecima diei hora",
      "duodecima diei hora",
    ];
    return dayHours[msToTime(msPastMidnight - sunrise, dayHourLength)];
  } else {
    //night after sunset

    const hoursPastSunset = msToTime(msPastMidnight - sunset, nightHourLength);
    return vigils[Math.floor(hoursPastSunset / 3)];
  }
};

function msToTime(millisec, hourlength) {
  var seconds = (millisec / 1000).toFixed(0);
  var secondsPerHour = (hourlength / 1000).toFixed(0);
  return Math.floor(seconds / secondsPerHour);
}

//convert number into roman numerals
export const romanizeNumber = (num) => {
  if (isNaN(num)) return NaN;
  var digits = String(+num).split(""),
    key = [
      "",
      "C",
      "CC",
      "CCC",
      "CD",
      "D",
      "DC",
      "DCC",
      "DCCC",
      "CM",
      "",
      "X",
      "XX",
      "XXX",
      "XL",
      "L",
      "LX",
      "LXX",
      "LXXX",
      "XC",
      "",
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
    ],
    roman = "",
    i = 3;
  while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
  return Array(+digits.join("") + 1).join("M") + roman;
};

// export const lightness = (iDate) => {
// 	const midnight = new Date(iDate).setHours(0, 0, 0, 0);
//   const msPastMidnight = iDate - midnight;
//   //   const position = navigator.geolocation.getCurrentPosition();
//   const position = {
//     coords: {
//       latitude: 41.9028,
//       longitude: 12.4964,
//     },
//   };
//   const lat = position.coords.latitude;
//   const lon = position.coords.longitude;

//   const sunrise = sunriseSet(iDate, lat, lon, true) - midnight;
//   const sunset = sunriseSet(iDate, lat, lon, false) - midnight;
//   const msInDay = sunset - sunrise;
//   const twentyFourHours = 24 * 60 * 60 * 1000;
//   const msInNight = twentyFourHours - msInDay;
//   const dayHourLength = msInDay / 12;
//   const nightHourLength = msInNight / 12;

//   if (msPastMidnight < sunrise) {
//     //still night before dawn
//     const hoursPastSunset = msToTime(
//       msPastMidnight + (twentyFourHours - sunset),
//       nightHourLength
//     );
//     return Math.floor(hoursPastSunset / 3);
//   } else if (msPastMidnight < sunset) {
//     //daylight hours

//     return msToTime(msPastMidnight - sunrise, dayHourLength);
//   } else {
//     //night after sunset

//     const hoursPastSunset = msToTime(msPastMidnight - sunset, nightHourLength);
//     return Math.floor(hoursPastSunset / 3);
//   }
// }

const romanizeDay = (iDate) => {
  let romanDays = [
    "dies Solis",
    "dies Lunae",
    "dies Martis",
    "dies Mercurii",
    "dies Iovis",
    "dies Veneris",
    "dies Saturnis",
  ];
  let todaysDay = romanDays[iDate.getDay()];
  return todaysDay;
};

//convert the date to roman version
const romanizeDate = (iDate) => {
  let iDay = iDate.getDate();
  let iMonth = iDate.getMonth() + 1;
  let iYear = iDate.getFullYear();

  //iMonth is 1-12
  //iDay is 1-31

  let months = [
    "Ianuarius",
    "Februarius",
    "Martius",
    "Aprilis",
    "Maius",
    "Iunius",
    "Iulius",
    "Augustus",
    "September",
    "October",
    "November",
    "December",
  ];
  let monthsAlt = [
    "Februarias",
    "Martias",
    "Apriles",
    "Maias",
    "Iunias",
    "Iulias",
    "Augustas",
    "Septembres",
    "Octobres",
    "Novembres",
    "Decembres",
    "Ianuarias",
  ];
  let monthsFirsts = [
    "Ianuariae",
    "Februariae",
    "Martiae",
    "Apriles",
    "Maiae",
    "Iunias",
    "Iuliae",
    "Augustae",
    "Septembres",
    "Octobres",
    "Novembres",
    "Decembres",
  ];

  let latinMonth = months[iMonth - 1];
  let latinNextMonth = monthsAlt[iMonth - 1];
  let latinMonthFirst = monthsFirsts[iMonth - 1];

  let nonesDay = 5;
  let idesDay = 13;
  let finalDay = 31;

  //set the correct date for each significant day
  switch (iMonth) {
    case 3:
    case 5:
    case 7:
    case 10:
      nonesDay = 7;
      idesDay = 15;
      break;

    case 4:
    case 6:
    case 9:
    case 11:
      finalDay = 30;
      break;

    case 2:
      if (isLeapYear(iYear)) {
        finalDay = 29;
      } else {
        finalDay = 28;
      }
      break;
    default:
  }

  //set the correct name for each day of the month, then return it
  let latinDay = "";

  //first of the month
  if (iDay == 1) {
    latinDay = "kalendae " + latinMonthFirst;
    return latinDay;
  }
  //after first, before nones
  else if (iDay > 1 && iDay < nonesDay - 1) {
    let daysBefore = nonesDay - iDay + 1;
    latinDay =
      "ante diem " + romanizeNumber(daysBefore) + " nonas " + latinMonth;
    return latinDay;
  } else if (iDay == nonesDay - 1) {
    latinDay = "pridie nonas " + latinMonth;
    return latinDay;
  }
  //nones
  else if (iDay == nonesDay) {
    latinDay = "nonae " + latinMonthFirst;
    return latinDay;
  }
  //after nones, before ides
  else if (iDay > nonesDay && iDay < idesDay - 1) {
    let daysBefore = idesDay - iDay + 1;
    latinDay =
      "ante diem " + romanizeNumber(daysBefore) + " idus " + latinMonth;
    return latinDay;
  } else if (iDay == idesDay - 1) {
    latinDay = "pridie idus " + latinMonth;
    return latinDay;
  }
  //ides
  else if (iDay == idesDay) {
    latinDay = "idus " + latinMonthFirst;
    return latinDay;
  }
  //after ides, before final day
  else if (iDay > nonesDay && iDay < finalDay) {
    let daysBefore = finalDay - iDay + 2;
    latinDay =
      "ante diem " + romanizeNumber(daysBefore) + " kalendas " + latinNextMonth;
    return latinDay;
  }
  //final day of the month
  else if (iDay == finalDay) {
    latinDay = "pridie kalendas " + latinNextMonth;
    return latinDay;
  } else {
    return (
      "error: iDay:" +
      iDay +
      ", nonesday:" +
      nonesDay +
      ", finalday:" +
      finalDay +
      ", latinnextmonth:" +
      latinNextMonth
    );
  }
};

const romanFullDate = (d) => {
  const iDate = d;

  let date = romanizeDate(iDate);
  let day = romanizeDay(iDate);

  let year = romanizeNumber(iDate.getFullYear());
  return (
    romanHour(iDate) +
    ", " +
    day.toUpperCase() +
    ", " +
    date.toUpperCase() +
    ", " +
    year.toUpperCase()
  );
};

// const dateFromInput = (iYear, iMonth, iDay) => {
//   let date;
//   if (!iYear || !iMonth || !iDay) {
//     date = new Date();
//   } else {
//     date = new Date(iYear, iMonth - 1, iDay, 12, 0);
//   }
//   let output = romanFullDate(date);
//   return output;
// };

export default romanFullDate;
