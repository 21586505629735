import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import romanFullDate, { romanHour } from "../romanjava";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
function Picker({ date, setDate, dateChanged, setDateChanged }) {
  const handleDateChange = (date) => {
    setDateChanged(true);
    setDate(new Date(date));
  };

  useEffect(() => {
    let interval = setInterval(() => {
      if (!dateChanged) setDate(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, [date, setDate, dateChanged, setDateChanged]);

  return (
    <div
      className="App-header"
      style={{
        backgroundColor: `${
          !romanHour(date).includes("vigilia") ? "#5671a5" : "#2e3354"
        }`,
      }}
    >
      <p
        style={{ padding: "1rem", lineHeight: "1.5" }}
        className="flex-font-big"
      >
        {romanFullDate(date).toUpperCase()}
      </p>
      <Flatpickr
        style={{ padding: "1rem" }}
        data-enable-time
        value={date}
        options={{
          altInput: true,
          altFormat: "H:i:S F j, Y",
          dateFormat: "Y-m-d",
        }}
        onChange={(date) => handleDateChange(date)}
      />
    </div>
  );
}

export default Picker;
