import React, { useEffect, useState } from "react";
import romanFullDate from "../romanjava";

function Current() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    let interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);
  return (
    <div className="App-header">
      <p style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
        {romanFullDate(time).toUpperCase()}.
      </p>
    </div>
  );
}

export default Current;
