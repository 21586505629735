import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import romanFullDate, { romanHour, romanizeNumber } from "../romanjava";

function About({ date }) {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    let interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  return (
    <div
      className="App-header"
      style={{
        backgroundColor: `${
          !romanHour(date).includes("vigilia") ? "#5671a5" : "#2e3354"
        }`,
      }}
    >
      <p
        className="flex-font-small"
        style={{
          paddingLeft: "2rem",
          paddingRight: "2rem",
          lineHeight: "1.5",
          textAlign: "left",
        }}
      >
        Dates are worked out according to the Roman calendar. Times use the
        natural day (dies naturalis), with twelve hours that run from sunrise to
        sunset, and four vigils of the night, each of which is three hours long.
        The length of an hour in the day is worked out as the amount of daylight
        time divided by twelve. The vigils are the amount of night time divided
        by four.
      </p>
    </div>
  );
}

export default About;
